/**
Tasks
 */

#tasks {
  //border-radius: 12px;
  overflow: hidden;

  .section-title {
    text-align: center;
    padding: 0.7rem 0;
    text-transform: uppercase;

    h1 {
      margin: 0;
    }
  }

  .lvl-0 {
    .section-title {
      //background-color: $green-800;
    }
  }

  .lvl-1 {
    .section-title {
      //background-color: $blue-800;
    }
  }

  .lvl-2 {
    .section-title {
      //background-color: $yellow-800;
    }
  }

  .MuiListItemButton-root {
    //&:not(.completed) {
    //  &:hover {
    //    background-color: $primary;
    //    cursor: pointer;
    //    color: $white;
    //  }
    //}

    &.completed {
      //background-color: #ececec;
      cursor:initial;
    }

  }

  .task-reward {
    background: $black;
    color: white;
    padding: 0.8rem;
    font-size: 1.3rem;
    text-align: center;
  }
}
