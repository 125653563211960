#navbar-wrapper {
  //height: 100vh;
  //background-color: $black;
  //
  //.link-wrapper {
  //  //padding: 1.7rem 0;
  //  //background-color: $black;
  //  transition: all 0.4s ease;
  //
  //  &.active {
  //    //background-color: #FAFAFA;
  //    border-radius: 12px 0 0 12px;
  //    margin-left: 1rem;
  //  }
  //}
  //
  //.nav-item {
  //  a {
  //    padding: 0;
  //  }
  //}
}

.nav-tooltip {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  offset: 2rem;

  .nav-title {
    font-size: 1.2rem;
    color: $black;
  }

  .arrow {
    visibility: hidden;
  }

  border-color: transparent;

  .tooltip-inner {
    background-color: $white !important;
    box-shadow: none;
    opacity: 1 !important;
    padding: 0.5rem 0.7rem;
    //border: $border-width solid $gray-300;
    text-align: start;
    border-radius: 8px !important;
  }

  .tooltip-arrow {
    &::before {
      border-color: transparent;
    }
  }


  &::before {
    content: '';
    border: none;
  }

}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(220deg) brightness(103%) contrast(102%);
}

.filter-blue {
  filter: invert(30%) sepia(100%) saturate(3.5) hue-rotate(210deg);
}
