// Font
$font-size-root: 12px;

/**
* Figma Colors
 */
$input-bg: #FFF;
$form-select-bg: #FFF;
//$black: #090320;
$black: #06053D;
$black-2: #39344C;
$deep-blue: #1C104B;
$deep-blue-800: #49406F;
$deep-blue-250: #C6C3D2;
$deep-blue-100: #D4D7DE;
$blue: #1947E5;
$blue-800: #8094FF;
$blue-100: #E9E7FC;
//$yellow: #FFBD12;
$yellow: #FFBD12;
$yellow-800: #FFD465;
$yellow-100: #FFF4CC;
$red: #F95A2C;
$red-800: #FF9692;
$red-100: #FFE8E8;

//$green: #11AF22;
//$green: #00C6AE;
$green: #2e7d32;
//$green-800: #14532D;
//$green-100: #DCFCE7;
//$green-500 : #22C55E;

$info: $blue-100;
$light: #C6C3D2;
$purple-100: #E9E7FC;
$dark-blue: #111C44;

$white: white;

$linear-color: linear-gradient(20deg, rgba(82, 79, 240, 0.04), rgba(169, 24, 222, 0.04)), #FAFAFA;

$linear-gray: linear-gradient(0deg, rgba(0, 0, 255, 0.02), rgba(0, 0, 255, 0.02)), #FAFAFA;

$linear-new: linear-gradient(135deg, #524ff0, #a918de);
$linear-new-2: linear-gradient(135deg, rgba(82, 79, 240, 0.75), rgba(169, 24, 222, 0.75));
$linear-new-3 : linear-gradient(135deg, rgba(82, 79, 240, 0.15), rgba(169, 24, 222, 0.15));

// Custom colors
$light-blue: #F8F8FF;
$light-blue-2: #F4F7FE;

//$disabled-color: #969BAB;

// Layout
$grid-gutter-width: 24px;
$pagination-padding-x: 0.5rem;
$pagination-padding-y: 0.7rem;
$border-radius: 10px;
$border-radius-2: 12px;

// Borders
//$border-color: #E4E4E7;
//$border-color: #39344C;
$border-color: rgba(224, 224, 224, 1);

$border-color-light: #E4E4E7;
$border-width: 1px;

// Box shadow
//$box-shadow: 0px 2px 0px #C6C3D2;
$box-shadow: none;
//$box-shadow: 0px 4px 0px #C6C3D2;
$box-shadow-success: 0 2px 0 $green;
$box-shadow-blue: 0px 4px 0px $blue-800;
$light-box-shadow: 0px 1px 0px $blue;

// Buttons
$btn-close-color: $black;
//$btn-font-size: 1.1rem;

// Misc
$body-color: $black;
$modal-backdrop-bg: rgba(0, 0, 0, 1);
$modal-backdrop-opacity: 0.4;
$link-color: $black;
$accordion-border-color: rgba(255, 255, 255, 0.8);

// Colors variables
$warning: $yellow-800;

//$success: $green;
$success: $green;

$danger: $red;

$primary: #06053D;
$secondary: $black;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $green,
        "info": $info,
        "warning": $yellow,
        "danger": $red,
        "light": $light,
        "dark": $black
);

//$bg-color: #F7F7F9;
//$bg-color:#F8F8FF;
//$bg-color: $linear-color;
//$card-bg: white;
$card-bg: white;

$modal-inner-padding: 2rem;

$form-label-margin-bottom: 0.25rem;

$spacer: 1rem;
$spacers: (
        0:0,
        1: $spacer * .25,
        2: $spacer * 0.75,
        3: $spacer * 1.5,
        4: $spacer * 2,
        5: $spacer * 4,
);

$purple: #483EA8;

// Values can be used when imported in .tsx file
:export {
    black: $black;
    deep-blue: $deep-blue;
    deep-blue-800: $deep-blue-800;
    deep-blue-250: $deep-blue-250;
    deep-blue-100: $deep-blue-100;
    blue: $blue;
    blue-800: $blue-800;
    blue-100: $blue-100;
    yellow: $yellow;
    red: $red;
    red-800: $red-800;
    red-100: $red-100;
    green: $green;
    green-800: $green;
    green-100: $green;
    //green-800: $green-800;
    //green-100: $green-100;
    info: $info;
    yellow-100: $yellow-100;
    purple-100: $purple-100;
    light-blue-2: $light-blue-2;
    dark-blue: $dark-blue;

}
