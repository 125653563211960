// Litepicker

.litepicker {
  box-shadow: 0 0 5px #ddd;
  border-radius: $border-radius;
  overflow: hidden;
  margin-top: 0.5rem;

  .container__months {
    box-shadow: none;
    border-radius: 0;
  }

  .month-item-header {
    font-family: "Rubik";
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 300 !important;
  }
}


.container__predefined-ranges {
  display: flex;
  flex-direction: column;
  justify-content: end;
  min-width: 150px;
  padding-bottom: 1rem;
  padding-left: 0.5rem;

  button {
    padding: 0.3rem 1rem !important;
    border-radius: $border-radius;

    &:hover {
      background-color: $blue;
      color: $white;
      cursor: pointer !important;
    }
  }
}
