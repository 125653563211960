@import 'design/tasks';
@import 'design/nav';
@import 'design/litepicker';
@import 'design/utilities';

// TODO - mieux à faire directement dans le composant
#new-feed-nav {
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-right: 10rem;
    }

    &:last-child {
      margin-left: 10rem;
    }
  }
}

//.carousel {
//    img {
//        max-height: 400px !important;
//        min-width: auto;
//    }
//}

.linear-bg {
  background: $linear-new-3 !important;
  border-color: transparent !important;
}

.feed-block-project-wrapper {
  //background:white;
  &:hover {
    background: $linear-new-3;
    border-color: transparent !important;
  }
}

.feed-block-project {
  text-decoration: none;
}
