/**
* CSSTransitions
 */
.app-content-transition-enter {
    opacity: 0;
    padding-top: 2rem;
}

.app-content-transition-enter-active {
    opacity: 1;
    transition: all 0.7s;
    padding-top: 0;
}

.app-content-transition-exit {
    opacity: 1;
    padding-top: 0;
}

.app-content-transition-exit-active {
    opacity: 0;
    transition: all 0.7s;
    padding-top: 2rem;
}

.app-transition-enter {
    opacity: 0;
}

.app-transition-enter-active {
    opacity: 1;
    transition: all 0.7s;
}

.app-transition-exit {
    opacity: 1;
}

.app-transition-exit-active {
    opacity: 0;
    transition: all 0.7s;
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

/**
*Global
 */

@media (min-width: 768px) {
    .animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }

    100% {
        //transform: translateY(0rem);
        transform: translateY(4.2rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}


/** Loading Div */
.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 20px;
    height: 20px;

    & .path {
        stroke: hsl(0, 0, 10);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    &.lg {
        width:30px;
        height:30px;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}


.animate-border {
    --border-size: 3px;
    --border-angle: 0turn;
    width: 60vmin;
    height: 50vmin;
    background-image: conic-gradient(
        from var(--border-angle),
        #ffffff,
        #ffffff 50%,
        #ffffff
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #000000, #000000);
    background-size: calc(100% - (var(--border-size) * 2))
    calc(100% - (var(--border-size) * 2)),
    cover;
    background-position: center center;
    background-repeat: no-repeat;

    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
        to {
            --border-angle: 1turn;
        }
    }

    //&:hover {
    //    animation-play-state: paused;
    //}
}


.item-enter {
    opacity: 0;
    transform: scale(0.9);
}

.item-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms, transform 500ms;
}

.item-exit {
    opacity: 1;
    transform: scale(1);
}

.item-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
}
