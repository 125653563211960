/**
 Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Newake';
  src: url("../fonts/newake/Newake-Font-Demo.otf") format("opentype");
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/inter/Inter-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "DM";
  src: url("../fonts/dm_sans/DMSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "DM";
  src: url("../fonts/dm_sans/DMSans-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "DM";
  src: url("../fonts/dm_sans/DMSans-Bold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Heming";
  src: url("../fonts/heming/Heming_Variable.ttf");
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/rubik/Rubik-VariableFont_wght.ttf");
}

/**
 Font-Sizes */
$fs-40: 3.2rem;
$fs-36: 3rem;
$fs-32: 2.6rem;
$fs-30: 2.4rem;
$fs-28: 2.2rem;
$fs-24: 2rem;
$fs-20: 1.5rem;
$fs-18: 1.3rem;
$fs-16: 1.2rem;
$fs-15: 1.1rem;
$fs-14: 1.1rem;
$fs-13: 1.1rem;
$fs-12: 1rem;
$fs-10: 0.9rem;
.fs-60 {
  font-size: 60px !important;
}

.fs-40 {
  font-size: $fs-40 !important;
}

.fs-36 {
  font-size: $fs-36 !important;
}

.fs-32 {
  font-size: $fs-32 !important;
}

.fs-30 {
  font-size: $fs-30 !important;
}

.fs-24 {
  font-size: $fs-24 !important;
}

.fs-20 {
  font-size: $fs-20 !important;
}

.fs-28 {
  font-size: $fs-28 !important;
}

.fs-18 {
  font-size: $fs-18 !important;
}

.fs-16 {
  font-size: $fs-16 !important;
}

.fs-15 {
  font-size: $fs-15 !important;
}

.fs-14 {
  font-size: $fs-14 !important;
}

.fs-12 {
  font-size: $fs-12 !important;
}

.fs-10 {
  font-size: $fs-10 !important;
}
