.rounded {
  border-radius:12px;
}

.border-gray {
  border: $border-width solid #cecece;
}

.f-active {
  //border-color: $blue;
  //background-color: $blue-100;
  //border: $border-width solid $blue-300;
}

.typeahead-transparent {
  .rbt-input {
    //background: transparent;
    //border-color:transparent;
  }
}

.border-hover:hover {
  border-color: $black;
}

.blue-shadow-hover {
  box-shadow: none ;
  transition: all 0.05s ease-in-out;

  &:hover, &:focus {
    box-shadow: $box-shadow-blue !important;;
    border-color: $blue-800 !important;
    //color: $blue;
  }

  &.active {
    box-shadow: $box-shadow-blue !important;;
    border-color: $blue-800 !important;
  }
}

.row-success {
  td {
    &:first-child {
      border-left: 4px solid $success !important;
    }

    //border-bottom:$border-width solid $success !important;
    &:last-child {
      //border-right:4px solid $success !important;
    }
  }
}

.f-card-hover {
  &:hover {
    border-color: $blue !important;
    box-shadow: $box-shadow-blue !important;
  }
}

.border-full {
  border: $border-width solid $border-color;
}

.border-light {
  border: $border-width solid $border-color;
}

.no-shadow {
  &:focus {
    box-shadow: none;
    //border-color: $gray-400;
  }
}

.unavailable {
  position: absolute;
  height: 101%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 255, 0.02), rgba(0, 0, 255, 0.02)), rgba(250, 250, 250, 0.8);
  top: 0;
  left: 0;
  z-index: 2;
  //filter:blur(8px);
  //background: transparent;
  &.dark {
    background-color:rgba(18, 18, 18, 0.6);
  }
}
